import { Drawer, Grid, Menu, MenuProps } from 'antd'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

const { useBreakpoint } = Grid

const items: MenuProps['items'] = [
  {
    label: 'Ubicación',
    key: 'ubicacion',
    children: [
      {
        label: <Link to={'/ubicacion/mapa'}>Mapa de ubicación</Link>,
        key: 'mapa',
      },
      {
        label: (
          <Link to={'/ubicacion/altos-guayacanes'}>
            <span style={{lineHeight:1.5, display:'block'}}>Condominio de Playa<br/>Altos de Guayacanes</span>
          </Link>
        ),
        key: 'altos-guayacanes',
      },
    ],
  },
  {
    label: 'Proyecto',
    key: 'proyecto',
    children: [
      {
        label: (
          <Link to="/proyecto/canoes-camino-mar">Canoes, Camino al Mar</Link>
        ),
        key: 'canoes-camino-mar',
      },
      {
        label: <Link to="/proyecto/master-plan">Master Plan</Link>,
        key: 'master-plan',
      },
      {
        label: <Link to="/proyecto/galeria">Galería</Link>,
        key: 'proyecto-galeria',
      },
      {
        label: <Link to="/proyecto/video">Video</Link>,
        key: 'video',
      },
    ],
  },
  {
    label: 'Casas',
    key: 'casas',
    children: [
      {
        label: <Link to="/casas/descripcion">Descripción</Link>,
        key: 'descripcion',
      },
      {
        label: <Link to="/casas/planos">Planos</Link>,
        key: 'planos',
      },
      {
        label: <Link to="/casas/galeria">Galería</Link>,
        key: 'casas-galeria',
      },
      {
        label: <Link to="/casas/360">Recorrido 360</Link>,
        key: '360',
      },
    ],
  },
  {
    label: <Link to="desarrolladores-aliados">Desarrolladores y Aliados</Link>,
    key: 'desarrolladores-aliados',
  },
  {
    label: <Link to="contactanos">Contáctanos</Link>,
    key: 'contactanos',
  },
]

interface NavMenuProps {
  onClose: () => void
  open: boolean
}
const NavMenu: React.FC<NavMenuProps> = ({ onClose, open }) => {
  const screens = useBreakpoint()

  const location = useLocation()

  const [current, setCurrent] = useState<string>()

  useEffect(() => {
    if (location.pathname) {
      const partesUrl = location.pathname.split('/')
      onClose()
      setCurrent(
        partesUrl[partesUrl.length - 1] !== 'galeria'
          ? partesUrl[partesUrl.length - 1]
          : partesUrl[partesUrl.length - 2] +
              '-' +
              partesUrl[partesUrl.length - 1],
      )
    }
  }, [location.pathname])

  if (screens.xs) {
    return (
      <Drawer
        placement="right"
        onClose={onClose}
        open={open}
        style={{ background: '#1b2241ec' }}
        size="default"
      >
        <MenuStyled
          style={{ minWidth: 0 }}
          selectedKeys={[current || '']}
          mode="inline"
          items={items}
        />
      </Drawer>
    )
  }

  return (
    <div>
      <MenuStyled
        selectedKeys={[current || '']}
        mode="horizontal"
        items={items}
      />
    </div>
  )
}
export default NavMenu

const MenuStyled = styled(Menu)`
  height: 60px;
  background-color: transparent;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  min-width: 550px;

  & .ant-menu-submenu-title {
    height: 60px;
  }

  & .ant-menu-title-content {
    line-height: 60px;
  }
`
