import styled from 'styled-components'

import AraujoLogo from '../../assets/footer/Araujo-y-segovia.png'
import AlianzaLogo from '../../assets/footer/alianza-fiduciaria.png'
import Firma from '../../assets/footer/de-la-vega.png'
import DeltaLogo from '../../assets/footer/delta.png'
import EscosaLogo from '../../assets/footer/escosa.png'

const Footer = () => {
  return (
    <>
      <Container>
        <Content>
          <span>Construye & Gerencia</span>
          <ImgContainer>
            <ImgContent>
              <img src={DeltaLogo} />
            </ImgContent>
            <ImgContent>
            <img src={EscosaLogo} />
            </ImgContent>
          </ImgContainer>
          <span>Arquitecto</span>
          <ImgContent>
            <img src={Firma} />
          </ImgContent>
          <span>Corredor inmobiliario</span>
          <ImgContent>
            <img src={AraujoLogo} />
          </ImgContent>
          <span>Fiduciaria</span>
          <ImgContent>
            <img src={AlianzaLogo} />
          </ImgContent>
        </Content>
      </Container>
      <div>
        <Section style={{ fontSize: '0.8rem' }}>
          Las imágenes publicadas son representaciones digitales del diseño y
          junto con los inmuebles exhibidos, pueden variar en su percepción y
          construcción final. Los datos aquí publicados pueden variar sin previo
          aviso. Antes de tomar su decisión de compra por favor infórmese en la
          sala de negocios y con su asesor comercial acerca de las condiciones y
          características del proyecto.{' '}
        </Section>
      </div>
    </>
  )
}

export default Footer

const ImgContainer = styled.div`
  display: flex;
  gap: 20px;
  /* Styles for small screens (up to 768px) */
  @media screen and (max-width: 426px) {
    flex-direction: column;
  }
`

const ImgContent = styled.div`
  img {
    width: 100%;
    object-fit: contain;
    aspect-ratio: 3/1;
    max-width: 150px;
  }
`

const Section = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  padding: 20px;
`

const Content = styled(Section)`
  font-size: 1rem;
  display: grid;
  grid-template-columns: auto 2fr auto 1fr auto 1fr auto 1fr;
  grid-template-rows: auto;
  align-items: center;
  gap: 20px;

  /* Styles for small screens (up to 768px) */
  @media screen and (max-width: 1024px) {
    grid-template-columns: auto 2fr auto 1fr;
  }

  /* Styles for small screens (up to 768px) */
  @media screen and (max-width: 768px) {
    grid-template-columns: auto 1fr;
  }

  /* Styles for small screens (up to 768px) */
  @media screen and (max-width: 426px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`

const Container = styled.div`
  padding: 20px 0;
  margin: 0 20px;
  background-color: #d3dbdf;
  margin-top: 100px;
  position: relative;
  &:after {
    content: "";
    width: 1px;
    height: 80px;
    position: absolute;
    top: -90px;
    left: 50%;
    background-color: #00000056;
  }
`
