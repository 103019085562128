import React from 'react'
import AppRoute from './components/app-route'

const App = () => {
  return (
    <React.Suspense>
      <AppRoute />
    </React.Suspense>
  )
}

export default App
