import { useEffect, useRef, useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Logo from '../../assets/images/logo.png'
import Footer from './footer'
import NavMenu from './nav-menu'

import { MenuOutlined } from '@ant-design/icons'
import { Button, Grid } from 'antd'
import { useAnimation } from 'framer-motion'

const { useBreakpoint } = Grid

const Layout = () => {
  const [scrolling, setScrolling] = useState(false)
  const controls = useAnimation()
  const [open, setOpen] = useState(false)

  const screens = useBreakpoint()

  const location = useLocation()

  const navRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    window.scrollTo({ top: 0 })
    if (navRef.current && navRef.current.style) {
      navRef.current.style.backgroundColor = 'transparent'
      navRef.current.style.top = '20px'
    }
  }, [location.pathname, navRef.current])

  useEffect(() => {
    const handleScroll = () => {
      if (navRef.current && navRef.current.style) {
        if (window.scrollY < 20) {
          navRef.current.style.top = 20 - window.scrollY + 'px'
        } else {
          navRef.current.style.top = '0px'
        }

        if (window.scrollY > 20) {
          if (!scrolling) {
            setScrolling(true)
            navRef.current.style.backgroundColor = '#1b2241d2'
            document.documentElement.classList.add('nav-color')
          }
        } else {
          if (scrolling) {
            setScrolling(false)
            navRef.current.style.backgroundColor = 'transparent'
            document.documentElement.classList.remove('nav-color')
          }
        }
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrolling, controls, navRef.current])

  return (
    <>
      <Container>
        <Header
          ref={navRef}
          // initial={{ backgroundColor: 'transparent' }}
          // animate={controls}
          // transition={{ duration: 0.5 }}
        >
          <Link to="/">
            {' '}
            <img src={Logo} style={{ height: 50 }} />{' '}
          </Link>
          {screens.xs && (
            <Button
              onClick={() => void setOpen(true)}
              size="large"
              type="text"
              icon={<MenuOutlined style={{ color: '#fff' }} />}
            />
          )}
          <NavMenu open={open} onClose={() => void setOpen(false)} />
        </Header>
        <Outlet />
      </Container>
      {location.pathname !== '/' && <Footer />}
    </>
  )
}
export default Layout

const Header = styled.div`
  position: fixed;
  width: calc(100% - 40px);
  max-width: calc(100dvw - 40px);
  left: 0;
  top: 20px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  /* backdrop-filter: blur(0px); */
  /* background-color: #1b2241d2; */
  transition: background-color 0.3s;
`

const Container = styled.div`
  /* min-height: calc(100dvh - 40px); */
  width: calc(100% - 40px);
  padding: 20px;
`
